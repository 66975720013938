
.Grid_Container{
    height: 100%;
    width: 100%;
    background: url("../../../images/Fond_Header_Accueil2.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-top: 15em;
    padding-bottom: 15em;
}
.commanderHeader {
    text-decoration: none;
    color: #FFFFFF;
}
.commanderHeader:hover {
    color: #FFFFFF;
}

.Div_Text_Header{
    height: 40%;
    font-size: 7em;
    font-family: Teko;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: -32vh;
}

#Btn_Commander_Accueil{
    font-size: var(--font-size-button);
    background-color: #C3232B;
    color: #FFFFFF;
    font-family: Teko;
    border-color: #C3232B;
    border-radius: 20px;
    padding: 8px 30px;    
}
#Btn_Commander_Accueil:hover {
    background-color: #db0b15;

}
#header-strong {
    background: rgba(0, 0, 0, 0.1);
}
/* responsive */
#header {
    height: 100vh;
    position: relative;
    z-index: 5;
}

/* for w < 960 or h < 730 */
@media screen and (max-width: 960px) , (max-height: 800px) {

    #Btn_Commander_Accueil{
        font-size: var(--font-size-button-mobile);
        padding: 8px 20px;  
    }
}

/* -------------- WIDTH --------------------- */
/* w <= 1104 */
@media screen and (max-width: 1104px) {
    .Div_Text_Header {
       font-size: 5em;
       margin-top: -10vh;
    }
}
/* 746 <= w <= 1104 */
@media screen and (max-width: 746px) {
    .Div_Text_Header {
       font-size: 4em;
       margin-top: -10vh;
    }
}

@media screen and (max-width: 608px) {
    .Div_Text_Header {
       font-size: 3em;
       margin-top: -2vh;
    }
}

@media screen and (max-width: 514px) {
    .Div_Text_Header {
       font-size: 2em;
       margin-top: 9vh;
    }
}

/* -------------- MIN WIDTH 332 --------------------- */


/* -------------- HEIGHT -------------- */

@media only screen and (max-width: 514px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 596px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 515px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 790px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 645px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 893px) {
    .Div_Text_Header {
        font-size: 6em;
        margin-top: -40vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 741px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 626px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 529px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}




/* -------------- MIN HEIGHT 550 -------------- */

/* -------------- WIDTH AND HEIGHT  --------------------- */

/* -------------- MIN WIDTH AND HEIGT --------------------- */

